import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import TopBarMarketing from './TopBarMarketing';
import Footer from './Footer';
import contentPerformanceImage from './images/content-performance.png';
import changePointDetectionImage from './images/change-point-detection.png';

// Example resource data
const resourceData = [
  {
    title: 'Measuring Content Performance in 2025',
    category: 'Guides',
    excerpt: 'Learn how to measure and optimize your content performance using Pettitt\'s test and engagement metrics.',
    authors: ['Richard Lawrence'],
    link: '/content-performance',
    imageUrl: contentPerformanceImage
  },
  {
    title: 'Change Point Detection: An Alternative to A/B Testing',
    category: 'Guides',
    excerpt: 'Explore Pettitt\'s test as a method to detect significant changes in your web metrics.',
    authors: ['Richard Lawrence'],
    link: '/change-point-detection',
    imageUrl: changePointDetectionImage
  }
];
  

const categories = [
  'All',
  'Guides'
];

const Resources = () => {
  // Filter state
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Filter handler
  const filteredResources = resourceData.filter(item => {
    if (selectedCategory === 'All') return true;
    return item.category === selectedCategory;
  });

  return (
    <div className="flex flex-col min-h-screen text-black">
      <Helmet>
        <title>Resources | contentEnhance</title>
        <meta
          name="description"
          content="Explore our latest resources and best practices to enhance your content strategy."
        />
      </Helmet>

      {/* Top Bar */}
      <TopBarMarketing />

      {/* Header / Hero section (optional) */}
      <div className="bg-gray-50 py-16 px-4 text-center">
        <h1 className="text-4xl font-bold mb-4">Resources</h1>
        <p className="text-lg text-gray-600">
          Browse our latest insights, guides, and case studies
        </p>
      </div>

      {/* Category Filter */}
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap gap-3 justify-center mt-4">
          {categories.map(cat => (
            <button
              key={cat}
              onClick={() => setSelectedCategory(cat)}
              className={`px-4 py-2 rounded-full border border-gray-300 ${
                selectedCategory === cat
                  ? 'bg-black text-white'
                  : 'bg-white text-black hover:bg-gray-100'
              }`}
            >
              {cat}
            </button>
          ))}
        </div>
      </div>

      {/* Resource Grid */}
      <div className="container mx-auto px-4 py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
          {filteredResources.map((resource, index) => (
            <div
              key={index}
              className="border rounded-lg shadow-sm overflow-hidden flex flex-col"
            >
              {/* Image */}
              {resource.imageUrl && (
                <img
                  src={resource.imageUrl}
                  alt={resource.title}
                  className="w-full h-48 object-cover"
                />
              )}
              {/* Content */}
              <div className="p-4 flex flex-col flex-grow">
                <p className="text-sm text-gray-500 mb-1">{resource.category}</p>
                <h3 className="text-lg font-semibold mb-2">{resource.title}</h3>
                <p className="text-gray-600 flex-grow">{resource.excerpt}</p>
                {/* Authors */}
                {resource.authors && resource.authors.length > 0 && (
                  <p className="text-sm text-gray-500 mt-4">
                    {resource.authors.join(', ')}
                  </p>
                )}
                {/* Link */}
                <a
                  href={resource.link}
                  className="mt-4 inline-block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-800 transition-colors"
                >
                  Read more
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Resources;
