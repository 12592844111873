import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const WritingGuidelines = () => {
    const [audienceInsight, setAudienceInsight] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [generationError, setGenerationError] = useState('');
    const { projectId } = useParams();
    const [userRole, setUserRole] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(''); 

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
                if (!response.data.isAuthenticated) {
                    navigate('/login'); // Redirect to login if not authenticated
                }
            } catch (error) {
                console.error('Authentication check failed:', error);
                navigate('/login'); // Redirect to login on error
            }
        };

        checkAuthentication();
    }, [navigate]);

    useEffect(() => {
        // Fetch audience insight
        const fetchAudienceInsight = () => {
            axios.get(`https://api.contentenhance.com/api/writing-guidelines/${projectId}`, {
                withCredentials: true
            })
                .then(response => {
                    setAudienceInsight(response.data.writing_style || '');
                })
                .catch(error => {
                    if (error.response) {
                        // Check for response status
                        if (error.response.status === 401) {
                            navigate('/login'); // Redirect to login
                        } else if (error.response.status === 403) {
                            setErrorMessage('You do not have permission to access this resource.');
                            // Optionally redirect to a different page
                        } else {
                            console.error("There was an error fetching the audience insights: ", error);
                        }
                    } else {
                        // Handle errors not related to a response
                        console.error("There was an error fetching the audience insights: ", error);
                    }
                });
        };

        // Fetch user role for the project
        const fetchUserRole = () => {
            axios.get(`https://api.contentenhance.com/api/projects/${projectId}/check_role`, { withCredentials: true })
                .then(response => {
                    setUserRole(response.data.role);
                })
                .catch(error => console.error("There was an error fetching the user role: ", error));
        };

        fetchAudienceInsight();
        fetchUserRole();
    }, [projectId]);

    const handleSave = () => {
        if (!['admin', 'editor'].includes(userRole.toLowerCase())) {
            alert("You don't have permission to save edits.");
            return;
        }
        // Send the updated audience insight to the backend
        axios.post(`https://api.contentenhance.com/api/writing-guidelines/${projectId}`, 
                   { writing_style: audienceInsight },
                   { withCredentials: true } // Include this line to send cookies
        )
        .then(response => {
            alert('Writing guidelines updated successfully');
        })
        .catch(error => {
            console.error("There was an error updating the writing guidelines: ", error);
        });
    };

    return (
        <div className="flex flex-col h-screen bg-darkBlue">
            <TopBar />
            <div className="flex h-screen bg-lighterDarkBlue text-white">
                <Sidebar />
                <div className="flex-1 bg-gray-850 p-10 overflow-auto">
                    <div className="flex mb-8">
                        <h1 className="text-white text-base mr-2">
                            Writing guidelines
                        </h1>
                    </div>
                    <textarea
                        className="w-full bg-gray-900 text-gray-200 text-sm p-4 h-96 rounded-md"
                        value={audienceInsight}
                        onChange={(e) => setAudienceInsight(e.target.value)}
                    />
                    <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded text-sm" onClick={handleSave}>
                        Save
                    </button>
                    {generationError && <p className="text-red-500 text-sm mt-2">{generationError}</p>}
                </div>
            </div>
        </div>
    );
}

export default WritingGuidelines;