import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const OrganizationCookieManager = () => {
  const [cookies, setCookies] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [userRole, setUserRole] = useState('');

  // Separate states for Cookies
  const [cookieFeedbackMsg, setCookieFeedbackMsg] = useState('');
  const [cookieError, setCookieError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Separate states for Local Storage
  const [localStorageFeedbackMsg, setLocalStorageFeedbackMsg] = useState('');
  const [localStorageError, setLocalStorageError] = useState('');

  const [showMobileModal, setShowMobileModal] = useState(false);
  const navigate = useNavigate();
  const [localStorageItems, setLocalStorageItems] = useState([]);
  const { organizationId } = useParams();
 

  // ------------------------------------------------------
  // AUTH & USER ROLE
  // ------------------------------------------------------
  useEffect(() => {
      const checkAuthentication = async () => {
          try {
              const response = await axios.get('https://api.contentenhance.com/api/auth/check', { withCredentials: true });
              if (!response.data.isAuthenticated) {
                  navigate('/login'); // Redirect to login if not authenticated
              }
          } catch (error) {
              console.error('Authentication check failed:', error);
              navigate('/login'); // Redirect to login on error
          }
      };

      checkAuthentication();
  }, [navigate, organizationId]);
  
  useEffect(() => {
      const fetchUserRoleForOrganization = async () => {
          try {
              const response = await axios.get(`https://api.contentenhance.com/api/organizations/org/${organizationId}/check_role`, { withCredentials: true });
              if (response.status === 200) {
                  setUserRole(response.data.role); // Handle successful response
              } else {
                  console.error('Unexpected status code:', response.status);
                  // Handle other statuses explicitly if needed
              }
          } catch (error) {
              if (axios.isAxiosError(error)) {
                  // Check if the error is due to a 401 Unauthorized response
                  if (error.response && error.response.status === 401) {
                      navigate('/login'); // Redirect to login page
                  } else if (error.response && error.response.status === 403) {
                      console.error('Error:', error.message);
                      setErrorMessage('You do not have permission to access this resource.');
                  } else {
                      console.error('Error fetching user role for organization:', error.message);
                  }
              } else {
                  // Handle non-Axios errors
                  console.error('An unexpected error occurred:', error);
              }
          }
      };

      fetchUserRoleForOrganization();
  }, [organizationId, navigate]); // Depend on organizationId and navigate

  // ------------------------------------------------------
  // FETCH COOKIES
  // ------------------------------------------------------
  useEffect(() => {
    const fetchCookies = async () => {
      if (!organizationId) return;

      try {
        const response = await axios.get(
          `https://api.contentenhance.com/api/fetch-cookies/org/${organizationId}`,
          { withCredentials: true }
        );
        setCookies(response.data.cookies || []);
      } catch (error) {
        console.error('Error fetching cookies:', error);
      }
    };
    fetchCookies();
  }, [organizationId]);

  // ------------------------------------------------------
  // FETCH LOCAL STORAGE
  // ------------------------------------------------------
  useEffect(() => {
    const fetchLocalStorageItems = async () => {
      if (!organizationId) return;

      try {
        const response = await axios.get(
          `https://api.contentenhance.com/api/fetch-localstorage/org/${organizationId}`,
          { withCredentials: true }
        );
        setLocalStorageItems(response.data.localStorage || []);
      } catch (error) {
        console.error('Error fetching localStorage items:', error);
      }
    };
    fetchLocalStorageItems();
  }, [organizationId]);

  // ------------------------------------------------------
  // RESPONSIVE MODAL
  // ------------------------------------------------------
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setShowMobileModal(true);
      } else {
        setShowMobileModal(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ------------------------------------------------------
  // FEEDBACK HELPERS
  // ------------------------------------------------------
  const handleCookieFeedback = (message, isError = false) => {
    if (isError) {
      setCookieError(message);
      setTimeout(() => setCookieError(''), 5000);
    } else {
      setCookieFeedbackMsg(message);
      setTimeout(() => setCookieFeedbackMsg(''), 5000);
    }
  };

  const handleLocalStorageFeedback = (message, isError = false) => {
    if (isError) {
      setLocalStorageError(message);
      setTimeout(() => setLocalStorageError(''), 5000);
    } else {
      setLocalStorageFeedbackMsg(message);
      setTimeout(() => setLocalStorageFeedbackMsg(''), 5000);
    }
  };

  // ------------------------------------------------------
  // COOKIE FUNCTIONS
  // ------------------------------------------------------
  const validateCookie = (cookie) => {
    if (!cookie.name.trim()) {
      return 'Cookie name is required.';
    }
    if (!cookie.domain.trim()) {
      return 'Cookie domain is required.';
    }
    if (cookie.sameSite && !['Lax', 'Strict', 'None'].includes(cookie.sameSite)) {
      return 'Invalid SameSite value.';
    }
    if (cookie.expiration && isNaN(Date.parse(cookie.expiration))) {
      return 'Invalid expiration date.';
    }
    return null; 
  };

  const saveAllCookies = () => {
    let hasError = false;
    cookies.forEach((cookie, index) => {
      const validationError = validateCookie(cookie);
      if (validationError) {
        handleCookieFeedback(
          `Error in cookie ${index + 1}: ${validationError}`,
          true
        );
        hasError = true;
      }
    });
    if (hasError) return;

    cookies.forEach(submitCookie);
  };

  const submitCookie = async (cookie, index) => {
    if (!['admin', 'editor'].includes(userRole)) {
      handleCookieFeedback("You don't have permission to perform this action.", true);
      return;
    }
    const validationError = validateCookie(cookie);
    if (validationError) {
      handleCookieFeedback(`Cookie validation failed: ${validationError}`, true);
      return;
    }

    try {
      const response = await axios.post(
        `https://api.contentenhance.com/api/cookies/org/${organizationId}`,
        cookie,
        {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data?.cookie_id) {
        const updatedCookies = [...cookies];
        updatedCookies[index].cookie_id = response.data.cookie_id;
        setCookies(updatedCookies);
        handleCookieFeedback('Cookie saved successfully!');
      } else {
        handleCookieFeedback('Unexpected response from server.', true);
      }
    } catch (error) {
      console.error('Error saving cookie:', error);
      const apiErrorMessage =
        error.response?.data?.message || 'Failed to save cookie. Please try again.';
      handleCookieFeedback(apiErrorMessage, true);
    }
  };

  const addCookie = () => {
    if (cookies.some((cookie) => !cookie.name.trim())) {
      handleCookieFeedback(
        'Please complete existing cookie before adding another.',
        true
      );
      return;
    }
    setCookies([
      ...cookies,
      {
        name: '',
        value: '',
        domain: '',
        path: '',
        expiration: '',
        secure: false,
        httpOnly: false,
        sameSite: '',
      },
    ]);
  };

  const updateCookie = (index, field, value) => {
    const newCookies = [...cookies];
    newCookies[index][field] = value;
    setCookies(newCookies);
  };

  const deleteCookie = async (index) => {
    if (!['admin', 'editor'].includes(userRole)) {
      alert("You don't have permission to perform this action.");
      return;
    }
    const cookie = cookies[index];
    if (cookie.cookie_id) {
      try {
        await axios.delete(
          `https://api.contentenhance.com/api/delete-cookies/${cookie.cookie_id}`,
          { withCredentials: true }
        );
        const newCookies = [...cookies];
        newCookies.splice(index, 1);
        setCookies(newCookies);
        handleCookieFeedback('Cookie deleted successfully');
      } catch (error) {
        console.error('Error:', error);
        handleCookieFeedback('Failed to delete cookie', true);
      }
    }
  };

  // For the expiration date field
  const commonStyles =
    'flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 rounded-md shadow-sm ' +
    'focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900 cursor-pointer';

  const ExpiryDatePicker = React.memo(({ cookie, updateCookie, index }) => {
    const [localExpiration, setLocalExpiration] = useState(cookie.expiration || '');
    const [showInput, setShowInput] = useState(false);

    const handleChange = (e) => {
      setLocalExpiration(e.target.value);
    };

    const handleBlur = () => {
      if (localExpiration) {
        updateCookie(index, 'expiration', localExpiration);
      }
      setShowInput(false);
    };

    return (
      <span className={commonStyles} style={{ height: '38px', display: 'flex', alignItems: 'center' }}>
        {showInput || localExpiration ? (
          <input
            className="text-black"
            type="datetime-local"
            value={localExpiration}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ flexGrow: 1 }}
          />
        ) : (
          <span onClick={() => setShowInput(true)} style={{ cursor: 'pointer' }}>
            Expiry date
          </span>
        )}
      </span>
    );
  });

  // ------------------------------------------------------
  // LOCAL STORAGE METHODS (DB version)
  // ------------------------------------------------------
  const addLocalStorageItem = () => {
    if (localStorageItems.some((item) => !item.key.trim())) {
      handleLocalStorageFeedback('Please complete existing key before adding another.', true);
      return;
    }
    setLocalStorageItems([...localStorageItems, { key: '', value: '' }]);
  };

  const updateLocalStorageItem = (index, field, newValue) => {
    const updated = [...localStorageItems];
    updated[index][field] = newValue;
    setLocalStorageItems(updated);
  };

  const saveAllLocalStorage = async () => {
    if (!['admin', 'editor'].includes(userRole)) {
      handleLocalStorageFeedback("You don't have permission to perform this action.", true);
      return;
    }
    try {
      for (let i = 0; i < localStorageItems.length; i++) {
        await submitLocalStorageItem(localStorageItems[i], i);
      }
      handleLocalStorageFeedback('All local storage items saved successfully!');
    } catch (err) {
      console.error('Error saving localStorage items:', err);
      handleLocalStorageFeedback('Failed to save localStorage items.', true);
    }
  };

  const submitLocalStorageItem = async (item, index) => {
    if (!item.key.trim()) {
      throw new Error('Key is required.');
    }
    const payload = {
      local_storage_id: item.local_storage_id,
      key: item.key,
      value: item.value,
    };
    const response = await axios.post(
      `https://api.contentenhance.com/api/localstorage/org/${organizationId}`,
      payload,
      {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      }
    );
    if (response.data?.local_storage_id) {
      const updated = [...localStorageItems];
      updated[index].local_storage_id = response.data.local_storage_id;
      setLocalStorageItems(updated);
    }
  };

  const deleteLocalStorageItem = async (index) => {
    if (!['admin', 'editor'].includes(userRole)) {
      handleLocalStorageFeedback("You don't have permission to perform this action.", true);
      return;
    }
    const item = localStorageItems[index];
    if (!item.local_storage_id) {
      // If not in DB, just remove from state
      const updated = [...localStorageItems];
      updated.splice(index, 1);
      setLocalStorageItems(updated);
      return;
    }
    try {
      await axios.delete(
        `https://api.contentenhance.com/api/delete-localstorage/${item.local_storage_id}`,
        { withCredentials: true }
      );
      const updated = [...localStorageItems];
      updated.splice(index, 1);
      setLocalStorageItems(updated);
      handleLocalStorageFeedback('Local storage item deleted successfully!');
    } catch (err) {
      console.error('Error deleting local storage item:', err);
      handleLocalStorageFeedback('Failed to delete local storage item.', true);
    }
  };

  // ------------------------------------------------------
  // RENDER
  // ------------------------------------------------------
  return (
    <div className="flex flex-col h-screen bg-darkBlue">
      <TopBar />
      <div className="flex h-screen bg-lighterDarkBlue text-white">
        <Sidebar />
        <div className="flex-1 p-10 overflow-auto">
          {/* COOKIE SECTION */}
          <h1 className="text-base mb-2">Cookie manager (beta)</h1>
          <p className="text-sm mb-8">
            Add cookies below that will then apply to our image capturing service,
            so it can access specific sections of your website (behind a login for example). <strong>Cookies added here will apply to ALL projects for your organization</strong>. 
          </p>

          {showMobileModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1300]">
              <div className="w-full max-w-md p-4 space-y-2 rounded-lg bg-gray-900 shadow-2xl z-[1300]">
                <button
                  onClick={() => setShowMobileModal(false)}
                  className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 text-3xl p-2 z-[1300]"
                >
                  &times;
                </button>
                <h2 className="text-center text-gray-200 text-lg z-[1300]">
                  Scroll right after adding cookie
                </h2>
                <p className="text-center text-gray-400 text-sm z-[1300]">
                  The experience is currently ideally suited for larger screens,
                  but scroll right for all cookie fields when you add a cookie.
                </p>
                <div className="flex justify-center mt-4">
                  <button
                    onClick={() => setShowMobileModal(false)}
                    className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 text-sm z-[1300]"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {cookies.map((cookie, index) => (
            <div key={index} className="flex gap-4 mb-4 text-gray-700 min-w-[1200px]">
              <input
                className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 
                           rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
                           focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                placeholder="Name"
                value={cookie.name}
                onChange={(e) => updateCookie(index, 'name', e.target.value)}
              />
              <input
                className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 
                           rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
                           focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                placeholder="Value"
                value={cookie.value}
                onChange={(e) => updateCookie(index, 'value', e.target.value)}
              />
              <input
                className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 
                           rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
                           focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                placeholder="Domain"
                value={cookie.domain}
                onChange={(e) => updateCookie(index, 'domain', e.target.value)}
              />
              <input
                className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 
                           rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
                           focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                placeholder="Path"
                value={cookie.path}
                onChange={(e) => updateCookie(index, 'path', e.target.value)}
              />
              <ExpiryDatePicker
                cookie={cookie}
                updateCookie={updateCookie}
                index={index}
              />
              <select
                className="text-sm appearance-none px-3 py-1 border border-gray-600 
                           rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
                           focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                value={cookie.sameSite}
                onChange={(e) => updateCookie(index, 'sameSite', e.target.value)}
              >
                <option value="" disabled>
                  Same site
                </option>
                <option value="Lax">Lax</option>
                <option value="Strict">Strict</option>
                <option value="None">None</option>
              </select>
              <input
                type="checkbox"
                checked={cookie.secure}
                onChange={(e) => updateCookie(index, 'secure', e.target.checked)}
              />
              <span className="text-sm text-white py-2"> Secure</span>
              <input
                type="checkbox"
                checked={cookie.httpOnly}
                onChange={(e) => updateCookie(index, 'httpOnly', e.target.checked)}
              />
              <span className="text-sm text-white py-2">HttpOnly</span>
              <button
                className="bg-red-500 text-white p-2 text-sm rounded-md hover:bg-red-700"
                onClick={() => deleteCookie(index)}
              >
                Delete
              </button>
            </div>
          ))}

          <button
            className="bg-gray-700 text-white p-2 mt-4 text-sm rounded-md hover:bg-gray-900"
            onClick={addCookie}
          >
            + Add Cookie
          </button>

          {/* 
            We display Cookie feedback right below the Cookie section. 
          */}
          <div className="text-sm flex justify-center mt-8">
            <button
              className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={saveAllCookies}
            >
              Save Cookies
            </button>
          </div>

          <div className="mt-4 justify-center flex block">
            {cookieFeedbackMsg && (
              <div className="alert alert-success justify-center block text-sm text-white">
                {cookieFeedbackMsg}
              </div>
            )}
            {cookieError && (
              <div className="alert alert-danger justify-center block text-sm text-white">
                {cookieError}
              </div>
            )}
          </div>

          {/* LOCAL STORAGE SECTION */}
          <div className="mt-12">
            <h2 className="text-base mb-2">Local storage (beta)</h2>
            <p className="text-sm mb-8">
              Add key/value pairs below to be used for the image capturing service. These can be used for persistent data (e.g., user preferences). <strong>Local storage items added here will apply to ALL projects for your organization</strong>.
            </p>

            {localStorageItems.map((item, index) => (
              <div key={index} className="flex gap-4 mb-4 text-gray-700 min-w-[600px]">
                <input
                  className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 
                             rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
                             focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                  placeholder="Key"
                  value={item.key}
                  onChange={(e) => updateLocalStorageItem(index, 'key', e.target.value)}
                />
                <input
                  className="flex-1 text-sm appearance-none px-3 py-1 border border-gray-600 
                             rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
                             focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-900"
                  placeholder="Value"
                  value={item.value}
                  onChange={(e) => updateLocalStorageItem(index, 'value', e.target.value)}
                />
                <button
                  className="bg-red-500 text-white p-2 text-sm rounded-md hover:bg-red-700"
                  onClick={() => deleteLocalStorageItem(index)}
                >
                  Delete
                </button>
              </div>
            ))}

            <button
              className="bg-gray-700 text-white p-2 mt-4 text-sm rounded-md hover:bg-gray-900"
              onClick={addLocalStorageItem}
            >
              + Add Local Storage Item
            </button>

            {/* 
              Display Local Storage feedback near the Local Storage section. 
            */}
            <div className="text-sm flex justify-center mt-8">
              <button
                className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={saveAllLocalStorage}
              >
                Save Local Storage
              </button>
            </div>

            <div className="mt-4 justify-center flex block">
              {localStorageFeedbackMsg && (
                <div className="alert alert-success justify-center block text-sm text-white">
                  {localStorageFeedbackMsg}
                </div>
              )}
              {localStorageError && (
                <div className="alert alert-danger justify-center block text-sm text-white">
                  {localStorageError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationCookieManager;
