import React, { useState, useEffect, useRef, useCallback } from 'react';
import ImportUrlsModal from './ImportUrlsModal';
import { useParams, Link, useMatch, useNavigate } from 'react-router-dom';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleIcon from '@mui/icons-material/People';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CookieIcon from '@mui/icons-material/Cookie';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CreateIcon from '@mui/icons-material/Create'; // Added icon
import axios from 'axios';
import symbol from './contentenhance-new-project.png';
import './Sidebar.css';
import debounce from 'lodash/debounce';
import LZString from 'lz-string';


function Sidebar({ onUrlsSubmitted, userRole }) {
  const { projectId, urlId, organizationId } = useParams();
  const navigate = useNavigate();
  const matchProjects = useMatch('/projects/:projectId');
  const matchUrl = useMatch('/projects/:projectId/:urlId');
  const matchAudienceBrief = useMatch('/projects/:projectId/audience-brief');
  const matchWritingGuidelines = useMatch('/projects/:projectId/writing-guidelines');
  const matchInviteusers = useMatch('/projects/:projectId/project-invite');
  const matchCookieManager = useMatch('/projects/:projectId/cookie-manager');
  const matchRobotDetection = useMatch('/projects/:projectId/ai-access-check');
  const matchProjectSettings = useMatch('/projects/:projectId/details');
  const matchOrganizationProjects = useMatch('/organization/projects/:organizationId');
  const matchProjectOverview = useMatch('/projects');
  const matchUserSettings = useMatch('/user-settings');
  const matchRemoveUrl = useMatch('/projects/:projectId/:urlId/remove-url');
  const matchOrgDetails = useMatch('/organization/:organizationId/details');
  const matchOrgInvite = useMatch('/organization/:organizationId/organization-invite');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [currentSelection, setCurrentSelection] = useState('my-projects');
  const [currentOrganizationName, setCurrentOrganizationName] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const fetchOrganizationsAndProject = useCallback(async () => {
    const cachedOrganizations = localStorage.getItem('organizations');
    const cachedProjects = JSON.parse(LZString.decompress(localStorage.getItem('projects') || '{}') || '{}');

    if (cachedOrganizations && (!projectId || cachedProjects[projectId])) {
      const organizations = JSON.parse(cachedOrganizations);
      setOrganizations(organizations);

      if (organizationId) {
        const org = organizations.find(org => org.organization_id.toString() === organizationId);
        setCurrentSelection(organizationId);
        setCurrentOrganizationName(org ? org.organization_name : '');
      } else if (projectId) {
        const project = cachedProjects[projectId];
        if (project && project.organization_id) {
          const org = organizations.find(org => org.organization_id === project.organization_id);
          setCurrentSelection(project.organization_id.toString());
          setCurrentOrganizationName(org ? org.organization_name : '');
        }
      }
      return;
    }

    try {
      const [orgResponse, projectResponse] = await Promise.all([
        axios.get('https://api.contentenhance.com/api/user/organizations', { withCredentials: true }),
        projectId ? axios.get(`https://api.contentenhance.com/api/projects/${projectId}`, { withCredentials: true }) : Promise.resolve({ data: null }),
      ]);

      const organizations = (orgResponse.data.organizations || []).map(org => ({
        organization_id: org.organization_id,
        organization_name: org.organization_name,
        logo_url: org.logo_url,
      }));
      localStorage.setItem('organizations', JSON.stringify(organizations));
      setOrganizations(organizations);

      const projects = cachedProjects;
      if (projectId && projectResponse.data) {
        projects[projectId] = {
          project_id: projectResponse.data.project_id,
          organization_id: projectResponse.data.organization_id,
          project_name: projectResponse.data.project_name,
        };
        localStorage.setItem('projects', LZString.compress(JSON.stringify(projects)));
      }

      if (organizationId) {
        const org = organizations.find(org => org.organization_id.toString() === organizationId);
        setCurrentSelection(organizationId);
        setCurrentOrganizationName(org ? org.organization_name : '');
      } else if (projectId) {
        const project = projectResponse.data;
        if (project && project.organization_id) {
          const org = organizations.find(org => org.organization_id === project.organization_id);
          setCurrentSelection(project.organization_id.toString());
          setCurrentOrganizationName(org ? org.organization_name : '');
        }
      }
    } catch (error) {
      console.error('Error fetching organizations or project:', error);
    }
  }, [projectId, organizationId]);

  useEffect(() => {
    fetchOrganizationsAndProject();
  }, [fetchOrganizationsAndProject]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSelectionChange = debounce((selection) => {
    setCurrentSelection(selection);
    setDropdownOpen(false);
    if (selection === 'my-projects') {
      navigate('/projects');
      setCurrentOrganizationName('');
    } else {
      navigate(`/organization/projects/${selection}`);
      const org = organizations.find(org => org.organization_id.toString() === selection);
      setCurrentOrganizationName(org ? org.organization_name : '');
    }
  }, 300);

  const truncateName = (name) => {
    const workspaceString = `${name} workspace`;
    return workspaceString.length > 17 ? workspaceString.substring(0, 14) + '...' : workspaceString;
  };

  const currentOrganization = organizations.find(
    (org) => org.organization_id.toString() === currentSelection
  );

  const sidebarLinkStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#b3b3b3',
    padding: '8px 16px',
    textDecoration: 'none',
    gap: '8px',
    fontSize: '.875rem',
  };

  const iconStyle = {
    color: '#b3b3b3',
    fontSize: '.875rem',
  };

  const arrowStyle = {
    ...iconStyle,
    marginLeft: 'auto',
  };

  let links;

  if (matchUrl && urlId) {
    links = (
      <>
        <Link to={`/projects/${projectId}`} style={sidebarLinkStyle} className="sidebar-link">
          <AccountTreeIcon style={iconStyle} /> Back to project <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/details`} style={sidebarLinkStyle} className="sidebar-link">
          <SettingsIcon style={iconStyle} /> Project settings <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/audience-brief`} style={sidebarLinkStyle} className="sidebar-link">
          <ContactPageIcon style={iconStyle} /> Edit audience brief <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/writing-guidelines`} style={sidebarLinkStyle} className="sidebar-link">
          <CreateIcon style={iconStyle} /> Edit writing style <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/ai-access-check`} style={sidebarLinkStyle} className="sidebar-link">
          <SmartToyIcon style={iconStyle} /> Check AI access <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/cookie-manager`} style={sidebarLinkStyle} className="sidebar-link">
          <CookieIcon style={iconStyle} /> Project cookie manager <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/${urlId}/remove-url`} style={sidebarLinkStyle} className="sidebar-link">
          <RemoveCircleIcon style={iconStyle} /> Remove URL <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
      </>
    );
  } else if (matchProjects) {
    links = (
      <>
        <Link to={currentSelection === 'my-projects' ? "/projects" : `/organization/projects/${currentSelection}`} style={sidebarLinkStyle} className="sidebar-link">
          <AccountTreeIcon style={iconStyle} /> All projects <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/details`} style={sidebarLinkStyle} className="sidebar-link">
          <SettingsIcon style={iconStyle} /> Project settings <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/project-invite`} style={sidebarLinkStyle} className="sidebar-link">
          <PeopleIcon style={iconStyle} /> Invite project members <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <a onClick={() => setIsModalOpen(true)} style={sidebarLinkStyle} className="sidebar-link">
          <AddCircleIcon style={iconStyle} /> Add more URLs <ArrowForwardIosIcon style={arrowStyle} />
        </a>
        <Link to={`/projects/${projectId}/audience-brief`} style={sidebarLinkStyle} className="sidebar-link">
          <ContactPageIcon style={iconStyle} /> Edit audience brief <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/writing-guidelines`} style={sidebarLinkStyle} className="sidebar-link">
          <CreateIcon style={iconStyle} /> Edit writing style <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/ai-access-check`} style={sidebarLinkStyle} className="sidebar-link">
          <SmartToyIcon style={iconStyle} /> Check AI access <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/cookie-manager`} style={sidebarLinkStyle} className="sidebar-link">
          <CookieIcon style={iconStyle} /> Project cookie manager <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
      </>
    );
  } else if (matchAudienceBrief || matchWritingGuidelines || matchInviteusers || matchCookieManager || matchRobotDetection || matchProjectSettings || matchRemoveUrl) {
    links = (
      <>
        <Link to={`/projects/${projectId}`} style={sidebarLinkStyle} className="sidebar-link">
          <AccountTreeIcon style={iconStyle} /> Back to project <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/details`} style={sidebarLinkStyle} className="sidebar-link">
          <SettingsIcon style={iconStyle} /> Project settings <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/project-invite`} style={sidebarLinkStyle} className="sidebar-link">
          <PeopleIcon style={iconStyle} /> Invite project members <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/audience-brief`} style={sidebarLinkStyle} className="sidebar-link">
          <ContactPageIcon style={iconStyle} /> Edit audience brief <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/writing-guidelines`} style={sidebarLinkStyle} className="sidebar-link">
          <CreateIcon style={iconStyle} /> Edit writing style <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/ai-access-check`} style={sidebarLinkStyle} className="sidebar-link">
          <SmartToyIcon style={iconStyle} /> Check AI access <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/projects/${projectId}/cookie-manager`} style={sidebarLinkStyle} className="sidebar-link">
          <CookieIcon style={iconStyle} /> Project cookie manager <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
      </>
    );
  } else if (matchOrganizationProjects || matchOrgInvite || matchOrgDetails) {
    links = (
      <>
        <Link to="/workspaces" style={sidebarLinkStyle} className="sidebar-link">
          <WorkspacesIcon style={iconStyle} /> All workspaces <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/organization/${organizationId}/organization-invite`} style={sidebarLinkStyle} className="sidebar-link">
          <PeopleIcon style={iconStyle} /> Invite organization members <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/organization/${organizationId}/details`} style={sidebarLinkStyle} className="sidebar-link">
          <SettingsIcon style={iconStyle} /> Organization settings <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to={`/organization/${organizationId}/cookie-manager`} style={sidebarLinkStyle} className="sidebar-link">
          <CookieIcon style={iconStyle} /> Organization cookie manager <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
      </>
    );
  } else if (matchUserSettings) {
    links = (
      <>
        <Link to="/workspaces" style={sidebarLinkStyle} className="sidebar-link">
          <WorkspacesIcon style={iconStyle} /> All workspaces <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
        <Link to="/user-settings" style={sidebarLinkStyle} className="sidebar-link">
          <SettingsIcon style={iconStyle} /> User settings <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
      </>
    );
  } else {
    links = (
      <>
        <Link to="/user-settings" style={sidebarLinkStyle} className="sidebar-link">
          <SettingsIcon style={iconStyle} /> User settings <ArrowForwardIosIcon style={arrowStyle} />
        </Link>
      </>
    );
  }

  return (
<>
    {/* Mobile-only flyout arrow */}
    <div className="mobile-sidebar-toggle" onClick={toggleSidebar}>
      <span className={isSidebarOpen ? 'arrow open' : 'arrow'}>&#9664;</span>
    </div>
<div className={`sidebar ${isSidebarOpen ? 'open' : ''} w-64 bg-gray-900 shadow-md min-h-screen p-6`}>
      {organizations.length > 0 && (
        <div className="relative mb-4 text-sm" ref={dropdownRef}>
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className="w-full p-2 rounded bg-gray-800 text-gray-300 h-14 flex items-center justify-center"
          >
            {currentSelection === 'my-projects' ? (
              <>
                <img src={symbol} alt="My Projects" className="inline-block w-6 h-6 mr-2" />
                Personal workspace
              </>
            ) : (
              <>
                <img
                  src={currentOrganization?.logo_url || symbol}
                  alt="Organization Logo"
                  className="inline-block w-6 h-6 mr-2"
                />
                {truncateName(currentOrganization.organization_name)}
              </>
            )}
          </button>
          {dropdownOpen && (
            <div className="absolute z-10 mt-2 w-full bg-gray-800 rounded shadow-lg text-sm text-center">
              <div
                onClick={() => handleSelectionChange('my-projects')}
                className="p-4 flex items-center justify-center cursor-pointer hover:bg-gray-700 text-sm"
              >
                <img src={symbol} alt="My Projects" className="inline-block w-6 h-6 mr-2" />
                Personal workspace
              </div>
              {organizations.map((org) => (
                <div
                  key={org.organization_id}
                  onClick={() => handleSelectionChange(org.organization_id.toString())}
                  className="p-4 flex items-center justify-center cursor-pointer hover:bg-gray-700 text-sm"
                >
                  <img src={org.logo_url || symbol} alt={org.organization_name} className="inline-block w-6 h-6 mr-2" />
                  {truncateName(org.organization_name)}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <h2 className="text-gray-300 text-sm mb-4">Options</h2>
      <nav>{links}</nav>
      <ImportUrlsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} projectId={projectId} onUrlsSubmitted={onUrlsSubmitted} userRole={userRole} />
    </div>
	  </>
  );
}

export default Sidebar;
