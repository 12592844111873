import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveSankey } from '@nivo/sankey';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import axios from 'axios';

const SankeyDiagramExampleComponent = ({ url, nextUrls, topReferringUrls, topSourceMediums, totalSessions }) => {
    const { urlId } = useParams();
    const [nextUrlsState, setNextUrlsState] = useState(nextUrls);
    const [currentSource, setCurrentSource] = useState(null);
    const [isInteractiveData, setIsInteractiveData] = useState(false);
    const [currentSourceSessions, setCurrentSourceSessions] = useState(null);

    // Static dummy data for next URLs
	const dummyNextUrls = [
	    { next_url: '/home', sessions: 45, percentage: 25 },
	    { next_url: '/products', sessions: 30, percentage: 20 },
	    { next_url: '/blog', sessions: 25, percentage: 15 },
	    { next_url: '/about', sessions: 15, percentage: 10 },
	    { next_url: '/contact', sessions: 10, percentage: 5 },
	    { next_url: '/faq', sessions: 8, percentage: 4 },
	    { next_url: '/support', sessions: 7, percentage: 4 },
	    { next_url: '/careers', sessions: 6, percentage: 3 },
	    { next_url: '/privacy-policy', sessions: 5, percentage: 3 },
	    { next_url: '/terms-of-service', sessions: 4, percentage: 2 },
	    { next_url: '/signup', sessions: 3, percentage: 2 },
	    { next_url: '/login', sessions: 2, percentage: 1 },
	    { next_url: '/pricing', sessions: 1, percentage: 1 },
	];
    
    const handleClick = (item) => {
        let source = '';
        let sourceSessions = 0;

        if (item.id && item.id.startsWith('Referrer-')) {
            source = item.label.split(' (')[0].trim();
            sourceSessions = item.value;
        } else if (item.source && item.source.id.startsWith('Referrer-')) {
            source = item.source.label.split(' (')[0].trim();
            sourceSessions = item.value;
        } else {
            return;
        }

        if (source === "(direct) / (none)" || source === "(direct) /") {
            source = "(direct) / (none)";
        }

        // Use static dummy data instead of fetching from an API
        setNextUrlsState(dummyNextUrls);
        setCurrentSource(source);
        setCurrentSourceSessions(sourceSessions);
        setIsInteractiveData(true);
    };

    const resetState = () => {
        setNextUrlsState(nextUrls);
        setCurrentSource(null);
        setCurrentSourceSessions(null);
        setIsInteractiveData(false);
    };

    if ((!nextUrlsState || nextUrlsState.length === 0) && 
        (!topReferringUrls || topReferringUrls.length === 0) && 
        (!topSourceMediums || topSourceMediums.length === 0)) {
        return null;
    }
    

    const combinedReferrals = [
        ...(topReferringUrls || []).filter(ref => ref.referring_url !== 'Other').slice(0, 5),
        ...(topSourceMediums || []).filter(src => src.source_medium !== 'Other').slice(0, 5)
    ].map(item => ({ referrer: item.source_medium || item.referring_url, sessions: item.sessions }));
    
    const filteredReferrals = currentSource
        ? combinedReferrals.filter(ref => ref.referrer === currentSource)
        : combinedReferrals;

    const dedupedCombinedReferrals = Array.from(new Set(filteredReferrals.map(item => item.referrer)))
        .map(referrer => filteredReferrals.find(item => item.referrer === referrer));

    // Function to scale next URL sessions proportionally to match the source sessions
		const scaleNextUrlSessions = (nextUrls, sourceSessions) => {
		    const totalNextSessions = nextUrls.reduce((acc, nextUrl) => acc + nextUrl.sessions, 0);

		    // If next URLs' total sessions exceed the source, scale them down proportionally
		    if (totalNextSessions > sourceSessions) {
		        return nextUrls.map(nextUrl => ({
		            ...nextUrl,
		            scaledSessions: Math.round((nextUrl.sessions / totalNextSessions) * sourceSessions) // Round scaled sessions
		        }));
		    }

		    // If next URLs' sessions don't exceed the source, keep them as they are, but round the sessions
		    return nextUrls.map(nextUrl => ({
		        ...nextUrl,
		        scaledSessions: Math.round(nextUrl.sessions) // Round unscaled sessions
		    }));
		};
    
    // Updated calculation for percentage
    const calculatePercentage = (sessions, total) => {
        const percentage = (sessions / total) * 100;
        return percentage < 0.5 ? percentage.toFixed(1) : Math.round(percentage);
    };

    const effectiveTotalSessions = Math.max(
        filteredReferrals.reduce((acc, item) => acc + item.sessions, 0),
        totalSessions,
        nextUrlsState.reduce((acc, nextUrl) => acc + nextUrl.sessions, 0)
    );

    const sumOfSessions = nextUrlsState.reduce((acc, nextUrl) => acc + (nextUrl.sessions || 0), 0);

    const dedupedNextUrls = Array.from(new Set(
        (nextUrlsState || []).map(nextUrl => nextUrl.next_url)
    )).map(next_url => nextUrlsState.find(item => item.next_url === next_url))
    .slice(0, 10);

    // Scale next URLs only if there is interactive data (after click)
    const scaledNextUrls = isInteractiveData 
        ? scaleNextUrlSessions(dedupedNextUrls, currentSourceSessions)  // After click: scale next URLs' sessions
        : dedupedNextUrls;  // Before click: use original nextUrls without scaling
    
    const otherReferralsSessions = effectiveTotalSessions - filteredReferrals.reduce((acc, item) => acc + item.sessions, 0);

    let exitUnknownSessions, exitUnknownPercentage;

    if (isInteractiveData) {
        exitUnknownSessions = currentSourceSessions - sumOfSessions;
        exitUnknownPercentage = calculatePercentage(exitUnknownSessions, currentSourceSessions);
    } else {
        exitUnknownSessions = effectiveTotalSessions - sumOfSessions;
        exitUnknownPercentage = calculatePercentage(exitUnknownSessions, effectiveTotalSessions);
    }

    const formatPercentage = (percentage) => {
        return percentage < 0.5 ? percentage.toFixed(1) : Math.round(percentage);
    };

	const nodes = [
	    ...dedupedCombinedReferrals.map((ref, index) => ({
	        id: `Referrer-${ref.referrer}-${index}`,
	        label: `${ref.referrer} (${calculatePercentage(ref.sessions, effectiveTotalSessions)}%)`,
	        value: Math.round(ref.sessions),
	        x: 0, // Lock referrer nodes to the left side
	        y: index * 10 // Example vertical positioning, adjust as needed
	    })),
	    { id: `URL-${url}`, label: url, x: 50, y: 0 }, // Lock the main URL node
	    ...scaledNextUrls.map((nextUrl, index) => ({
	        id: `NextURL-${nextUrl.next_url}-${index}`,
	        label: `${nextUrl.next_url} (${calculatePercentage(isInteractiveData ? nextUrl.scaledSessions : nextUrl.sessions, currentSourceSessions || effectiveTotalSessions)}%)`,
	        value: isInteractiveData ? Math.round(nextUrl.scaledSessions) : Math.round(nextUrl.sessions),
	        x: 100, // Lock next URLs to the right side
	        y: index * 10 // Adjust vertical positioning
	    })),
	    ...(exitUnknownPercentage > 0 ? [{
	        id: 'Drop-off',
	        label: `Exit / Unknown (${formatPercentage(exitUnknownPercentage)}%)`,
	        x: 100,
	        y: scaledNextUrls.length * 10 // Position after the next URLs
	    }] : []),
	    ...(currentSource ? [] : [{
	        id: 'Other-Referrals',
	        label: `Other (${calculatePercentage(otherReferralsSessions, effectiveTotalSessions)}%)`,
	        x: 0,
	        y: dedupedCombinedReferrals.length * 10
	    }])
	];
	
    

	const links = [
	    ...dedupedCombinedReferrals.map((ref, index) => ({
	        source: `Referrer-${ref.referrer}-${index}`,
	        target: `URL-${url}`,
	        value: Math.round(ref.sessions) // Match node IDs exactly
	    })),
	    ...scaledNextUrls.map((nextUrl, index) => ({
	        source: `URL-${url}`,
	        target: `NextURL-${nextUrl.next_url}-${index}`,
	        value: isInteractiveData ? Math.round(nextUrl.scaledSessions) : Math.round(nextUrl.sessions) // Match node IDs exactly
	    })),
	    ...(exitUnknownPercentage > 0 ? [{
	        source: `URL-${url}`,
	        target: 'Drop-off',
	        value: exitUnknownSessions
	    }] : []),
	    ...(currentSource ? [] : [{
	        source: 'Other-Referrals',
	        target: `URL-${url}`,
	        value: otherReferralsSessions
	    }])
	];
   
    const CustomLinkTooltip = ({ link }) => (
        <div style={{ color: link.color, background: 'black', padding: '5px 10px', borderRadius: '3px' }}>
            {link.value} sessions
        </div>
    );
	
    const getDynamicMargins = () => {
           const screenWidth = window.innerWidth;
           if (screenWidth < 768) {
               return { top: 10, right: 100, bottom: 10, left: 100 }; // Smaller margins for mobile
           }
           return { top: 10, right: 175, bottom: 10, left: 175 }; // Default margins for desktop
       };

    return (
<div
        className="sankey-diagram-wrapper"
        style={{
            overflowX: 'auto', // Enable horizontal scrolling
            display: 'flex',
            justifyContent: 'start', // Align content to the start of the scrollable area
            padding: '10px', // Optional padding for spacing
			height: 400

        }}
    >
        <div className="sankey-diagram-container text-white text-sm font-normal" style={{ height: 280, width: '100%', display: 'flex', justifyContent: 'center', minWidth: '600px' }}>
            <div style={{ width: '100%' }}>
                <div className="flex justify-center mt-8 items-center">
                    <h3 className="text-center text-white text-base">Example Interactive Journey Flow Diagram</h3>
                    <Tooltip title="This diagram shows the flow of traffic from different sources to the main URL and their subsequent onward journeys. The referring URL part will be very accurate. The next URL will slightly depend on how your GA4 is setup - it may only pick up on external links.">
                        <IconButton size="small" style={{ color: 'grey', marginLeft: '8px' }}>
                            <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.9rem' }} />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="flex justify-center items-center mt-2">
                    <button
                        className="bg-gray-700 text-white px-3 py-1 text-sm rounded-md hover:bg-gray-900"
                        onClick={resetState}
                    >
                        Reset
                    </button>
                </div>
                <ResponsiveSankey
                    data={{ nodes, links }}
                    margin={getDynamicMargins()} // Adjust margins dynamically
					layout="horizontal" // Force a horizontal layout to prevent node swapping
                    align="justify" // Lock the node alignment to the left side
                    colors={{ scheme: 'pastel2' }}
                    nodeOpacity={1}
                    nodeHoverOthersOpacity={0.35}
                    nodeThickness={6}
                    nodeSpacing={8}
                    nodeBorderWidth={0}
                    nodeBorderColor={{ from: 'color', modifiers: [['brighter', 0.8]] }}
                    linkOpacity={0.5}
                    linkHoverOthersOpacity={0.1}
                    linkContract={3}
                    linkBlendMode="normal"
                    labelPosition="outside"
                    labelPadding={2}
                    labelTextColor="#ffffff"
                    label={(node) => node.label}
                    layers={['links', 'nodes', 'labels', 'legends']}
                    tooltip={CustomLinkTooltip}
                    theme={{
                        tooltip: {
                            container: {
                                background: 'black',
                            },
                        },
                    }}
                    onClick={handleClick}
                />
            </div>
        </div>
					</div>
    );
};

export default SankeyDiagramExampleComponent;
